form {
    display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 20px 20px 20px 0px;
      padding: 20px 20px 20px 0px;
      font-size: 18px;
      border-radius: 5px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }

  label {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  label span {
    margin-left: 20px;
  }

  input[type="number"], select, input[type="text"] {
      font-size: 18px;
      padding: 10px;
      border-radius: 5px;
      border: none;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      width: 200px;
      background-color: #f9f9f9;
    }

  input[type="submit"] {
    background-color: #1E90FF;
      color: #fff;
      font-size: 18px;
      padding: 10px 20px;
      border-radius: 5px;
      border: none;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      margin-top: 30px;
      cursor: pointer;
  }

  label:hover {
transform: scale(1.02);
}

input[type="submit"]:hover {
background-color: #0066CC;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

  /* Modal styles */
.modal-content {
    text-align: center;
    border-radius: 50%;
    padding: 50px;
  }
  
  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner-border {
    width: 3rem;
    height: 3rem;
  }

  input.invalid,
select.invalid {
  border: 2px solid red;
}
