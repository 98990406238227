.reactGiphySearchbox-poweredByGiphy {
    background-color: rgba(50, 100, 210, 0.8);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 4px;
    width: 123px;
  }
  .reactGiphySearchbox-poweredByGiphy img {
    display: block;
    width: 100%;
  }