.reactGiphySearchbox-searchForm-form {
    position: relative;
    margin: 10px;
  }
  .reactGiphySearchbox-searchForm-input {
    padding: 6px 4px;
    width: 100%;
    font-size: 1em;
    font-family: inherit;
    outline: none;
    border: 1px solid #ddd;
  }
  .reactGiphySearchbox-searchForm-input:focus {
    border-color: #bbb;
  }