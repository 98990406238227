.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .connecting {
    position: absolute;
    top: 90px;
    font-size: 13px;
    margin-left: 5px;
    color: antiquewhite;
  }

  .App-logo {
    height: 127px;
    width: 251px;
    pointer-events: none;
    border-radius: 2px;
    background-size: contain;
  background-image:url('logo4.png');
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
