.reactGiphySearchbox-componentWrapper {
    box-sizing: border-box;
    z-index: 9;
  }
  .reactGiphySearchbox-componentWrapper *,
  .reactGiphySearchbox-componentWrapper *:before,
  .reactGiphySearchbox-componentWrapper *:after {
    box-sizing: inherit;
  }
  .reactGiphySearchbox-listWrapper {
    overflow-y: auto;
  }