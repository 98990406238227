.chat-spinner {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  
  .chat-signal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background: radial-gradient(circle at center, #f3f3f3, #f3f3f3 35%, #222 35%);
    border-radius: 50%;
    box-shadow: 0 0 0 2px #f3f3f3, 0 0 0 4px #222, 0 0 0 6px #f3f3f3, 0 0 0 8px #222;
  }
  
  .chat-signal::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background: url('comments.jpg') no-repeat center center;
    background-size: contain;
    filter: drop-shadow(0 0 1px #f3f3f3) drop-shadow(0 0 2px #f3f3f3) drop-shadow(0 0 3px #f3f3f3) drop-shadow(0 0 4px #f3f3f3) drop-shadow(0 0 5px #222) drop-shadow(0 0 6px #222) drop-shadow(0 0 7px #222) drop-shadow(0 0 8px #222);
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }