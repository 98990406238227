.chat-bubbles {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    z-index: 9;
  max-width: 100%;
    margin-top: 60px;
    border-radius: 50px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;
  }
  
  .chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  z-index: 999;
    position: relative;
  max-height: 500px;
  background-color: rgba(245, 245, 245, 0.8);
  }
  
  .modal-content {
    padding: 0px 25px 0px 25px !important;
  }
  
  .modal-content-spinner {
    background-color: rgba(50, 50, 50, 0.9) !important;
  }