.time-ago {
    position: absolute;
    top: 0px;
    left: 30px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    width: calc(100% - 75px);
    color: rgba(10,100,100,0.9);
  }
  
  .time-ago-3 {
    position: absolute;
    top: 0px;
    left: 30px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    width: calc(100% - 75px);
    color: rgba(10,100,100,0.9);
  }
  
  .chat-bubble {
    position: relative;
    margin: 10px;
  }
  
  .chat-bubble.sent .cloud,
  .chat-bubble.received .cloud {
    display: inline-block;
    background-color: #dcf8c6;
    padding: 10px 15px;
    border-radius: 20px;
    position: relative;
  }
  
  .chat-bubble.sent .tail,
  .chat-bubble.received .tail {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
  
  .chat-bubble.sent .tail {
    right: -20px;
    border-left: 20px solid #dcf8c6;
  }
  
  .chat-bubble.received .tail {
    left: -20px;
    border-right: 20px solid #f1f0f0;
  }
  
  .chat-bubble.sent .bubbles,
  .chat-bubble.received .bubbles {
    position: absolute;
    bottom: -25px;
    left: -5px;
  }
  
  .chat-bubble.sent .bubbles span,
  .chat-bubble.received .bubbles span {
    display: block;
    background-color: #dcf8c6;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 3px;
    float: left;
  }
  
  .chat-bubble.sent .bubbles span:nth-child(2),
  .chat-bubble.received .bubbles span:nth-child(2) {
    margin-top: 10px;
  }
  
  .chat-bubble2 {
    margin: 10px;
    fill: #d0d8e0;
    height: 100px;
  min-height: 100px;
  width: 70%;
    padding: 10px;
  border-radius: 50px;
  opacity: 0;
    animation: fadein 0.5s ease-in forwards;
  }
  
  .chat-bubble3 {
    margin: 10px;
    fill: #d0d8e0;
    padding: 20px;
    width: 70%;
  border-radius: 50px;
  opacity: 0;
    animation: fadein 0.5s ease-in forwards;
  }
  
  .chat-bubble3.sent {
    transform: scaleX(1);
    background-color: rgba(200, 250, 250, 0.2);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  transition: all 0.2s ease;
  }
  
  .chat-bubble3.received {
    margin-left: auto;
    transform: scaleX(1);
    max-width: 500px;
    background-color: rgba(200, 250, 250, 0.4);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  }
  
  .chat-bubble2.sent {
    transform: scaleX(1);
    background-color: rgba(200, 250, 250, 0.2);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  padding: 10px;
  transition: all 0.2s ease;
  }
  
  .chat-bubble2.received {
    margin-left: auto;
    transform: scaleX(1);
    max-width: 500px;
    background-color: rgba(200, 250, 250, 0.4);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: all 0.2s ease;
  }
  
  @keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
  }
  
  
  .chat-bubble2.sent:hover {
  transform: scale(1.05);
  }
  
  .chat-bubble2.received:hover {
  transform: scale(1.05);
  }
  
  .chat-bubble3.sent:hover {
    transform: scale(1.05);
  }
  
  .chat-bubble3.received:hover {
    transform: scale(1.05);
  }
  
  .inner-text {
  background-image: url(cloud4.svg);
    background-repeat: no-repeat;
    background-size: 190px;
    background-position: top left;
    width: 100%;
    height: 100%;
    padding: 10px 10px 10px 10px;
    margin-left: 20px;
  }
  
  .inner-text-right {
  background-image: url(cloud5.svg);
    background-repeat: no-repeat;
    background-size: 190px;
    background-position: top right;
    width: 100%;
    height: 100%;
    padding: 10px 10px 10px 10px;
  }
  
  .inner-inner-text {
    padding: 0px 0px 0px 0px;
    overflow: auto;
    max-height: 55%;
    width: 98%;
  margin: 10px 0;
  font-size: 16px;
    line-height: 1.4;
    margin-left: -25px;
    min-height: 45px;
  font-family: 'Helvetica Neue', sans-serif;
  }
  
  .chat-bubble2.sent text {
  text-anchor: start;
  }
  
  .chat-bubble2 .tail {
    fill: inherit;
  }
  
  .chat-bubble2 .text {
    font-size: 14px;
    fill: #000;
    white-space: pre-wrap;
  }
  
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f5f5f5;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #c5c5c5;
    border-radius: 20px;
    border: 3px solid #f5f5f5;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #a5a5a5;
    border: 3px solid #f5f5f5;
  }
  
  .cloud {
    fill: #d0d8e0;
  }
  
  .cloud.sent {
    transform: scaleX(-1);
  }
  
  .cloud.received {
    background-color: #f1f0f0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  }